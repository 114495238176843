import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const NotFoundPage = lazy(() => import('@components/NotFound'));
const Reveal = lazy(() => import('@components/reveal/Reveal'));
const Wedding = lazy(() => import('@components/wedding/Wedding'));

const Events = lazy(() => import('@components/Events'));

const style = {
    "width": "100%",
    "textAlign": "center",
    "marginTop": "400px"
};
const renderLoader = () => <p style={style}>Loading...</p>;

class App extends React.Component {

    render() {

        return (
            <Suspense fallback={renderLoader()}>
                <div className="app">
                    <Switch>
                        <Route exact path="/" component={Events} />
                        <Route path="/wedding" component={Wedding} />
                        <Route path="/reveal" component={Reveal} />
                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
            </Suspense>
        );
    }
}

export default App;
